import { createRouter } from './router';
import store from './store';
import CreateApp from '../Blank/CreateApp';
import requireComponents from './requireComponents'

export default (context, params={}) => {
  return CreateApp({
      requireComponents: requireComponents,
      router: createRouter(),
      store,
      ...params
    },
    context
  );
};
