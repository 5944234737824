import LTConnectorChildren from '@/code/FastCash/Bundle/LTConnector/Resources/vue-routing/chatBot'
import returningUsersChildren from '@/code/FastCash/Bundle/LTConnector/Resources/vue-routing/chatBotMultiStepShort'
import cmsChildren from '@/code/FastCash/Bundle/Cms/Resources/vue-routing'
import partnersChildren from '@/code/FastCash/Bundle/Partner/Resources/vue-routing'
import leapMessageConnectorChildren from '@/code/FastCash/Bundle/Unsubscribe/Resources/vue-routing'

let appRoutes = [
  {
    path: '/',
    name: 'home',
    components: {
      wrapper: () => import(/* webpackChunkName: "home" */ '../views/index'),
      default: () => import(/* webpackChunkName: "home" */ '../components/SectionHero.vue'),
    }
  },
  {
    path: '/legal',
    name: 'legal',
    children: [...cmsChildren,...partnersChildren],
    components: {
      wrapper: () => import(/* webpackChunkName: "legal" */ '../../Blank/views/one-column-right.vue'),
    },
    props: {
      wrapper: {
        logoClass: 'one-column-right__logo-outer_small-padding',
        formType: 'form_internal',
      },
    },
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    children: leapMessageConnectorChildren,
    components: {
      wrapper: () => import(/* webpackChunkName: "unsubscribe" */ '../../Blank/views/one-column-right.vue'),
    },
    props: {
      wrapper: {
        logoClass: 'one-column-right__logo-outer_small-padding',
        formType: 'form_internal',
      },
    },
  },
  {
    name: 'repost',
    path: '/repost',
    components: {
      default: () => import(/* webpackChunkName: "repost" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/base/Interstitial.vue'),
      wrapper: () => import(/* webpackChunkName: "repost" */ '../../Blank/views/one-column-right.vue'),
    },
    props: {
      wrapper: { formType: 'form_interstitial' },
    },
  },
  {
    path: '/decline',
    children: [
      {
        name: 'decline',
        path: '',
        component: () => import(/* webpackChunkName: "decline" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/base/Decline.vue'),
      },
    ],
    components: {
      wrapper: () => import(/* webpackChunkName: "form" */ '../views/FormsWrapper.vue'),
    },
  },
  {
    path: '/boost',
    name: 'boost',
    components: {
      default: () => import(/* webpackChunkName: "boost" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/base/Boost.vue'),
      wrapper: () => import(/* webpackChunkName: "boost" */ '../../Blank/views/one-column-right.vue'),
    },
    props: {
      wrapper: { formType: 'form_interstitial' },
    },
  },
  {
    path: '/extra',
    children: [
      {
        name: 'extra',
        path: '',
        component: () => import(/* webpackChunkName: "extra" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/chatBot/wizard/Extra.vue'),
      },
    ],
    components: {
      wrapper: () => import(/* webpackChunkName: "form" */ '../views/FormsWrapper.vue'),
    },
  },
  {
    path: '/signin',
    name: 'signin',
    components: {
      default: () => import(/* webpackChunkName: "signin" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/base/Signin.vue'),
      wrapper: () => import(/* webpackChunkName: "signin" */ '../../Blank/views/one-column-right.vue'),
    },
    props: {
      default: { loanSelectType: 'hidden' },
      wrapper: { leftColumnClass: 'align-items-center justify-content-center', rightColumnClass: 'auth-page' },
    },
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: () => import(/* webpackChunkName: "login" */ '@/code/FastCash/Bundle/LTConnector/view/frontend/base/Login.vue'),
      wrapper: () => import(/* webpackChunkName: "login" */ '../../Blank/views/one-column-right.vue'),
    },
    props: {
      default: { loanSelectType: 'hidden' },
      wrapper: { leftColumnClass: 'align-items-center justify-content-center', rightColumnClass: 'auth-page' },
    },
  },
  {
    path: '/registration',
    name: 'registration',
    children: LTConnectorChildren,
    components: {
      wrapper: () => import(/* webpackChunkName: "form" */ '../views/FormsWrapper.vue'),
    },
  },
  {
    path: '/users',
    name: 'users',
    children: returningUsersChildren,
    components: {
      wrapper: () => import(/* webpackChunkName: "form" */ '../views/FormsWrapper.vue'),
    },
  },
  {
    name: 'Error404',
    path: '*',
    components: {
      default: () => import(/* webpackChunkName: "error404" */ '../../Blank/views/Error404.vue'),
      wrapper: () => import(/* webpackChunkName: "error404" */ '../../Blank/views/one-column-right.vue'),
    },
    props: {
      wrapper: {formType: 'form_interstitial'},
    },
  },
]

export default appRoutes
