import _ from 'lodash'
import store from '../../Blank/store'

const uiModules = {
    state: () => {
      const data = store.modules.ui.state();
      data.steps = ['apply1', 'apply2', 'apply2b', 'apply3', 'apply4', 'apply5', 'apply6', 'apply7', 'apply8', 'apply9', 'apply10',
        'apply11', 'apply12', 'apply12b', 'apply12c', 'apply13', 'apply14', 'apply15', 'apply16', 'apply17', 'apply18', 'apply19', 'apply20', 'apply21',
        'apply22', 'apply23', 'apply24', 'apply25', 'processingLong']
      return data;
    }
}

const contentModules = {
  state: () => {
    const data = store.modules.content.state();
    data.labels = {
      ...data.labels,
      formWrapperText: 'Decisions are moments away...',
      monthsEmployedLabel: 'How long have you had this income source?',
      decline: 'Continue to see your options'
    };
    return data;
  }
}

export default _.merge({}, store, {
  modules: {
    ui: uiModules,
    content: contentModules,
  },
})
