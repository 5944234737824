import Vue from 'vue'
import { createApp } from '../LeapTheory/chatbot/app'
import Api from '@/design/frontend/LeapTheory/Blank/services/Api'

const context = require('./context.json')
Vue.config.productionTip = false

const c = { ...context }
c.website.url_name = document.location.hostname
c.website.name = document.location.hostname
c.website.code = document.location.hostname

c.brand.name = document.location.hostname
c.brand.name_llc = `${document.location.hostname} LLC`

Api.defaults.baseURL = 'https://yourembeddedform.com/api/';
Api.defaults.withCredentials = true;

const { app } = createApp(context)
app.$mount('#app')
