import Vue from 'vue';
import Router from 'vue-router';
import VueScrollTo from 'vue-scrollto';

// see fix reference at https://github.com/vuejs/vue-router/issues/2881
// fix 2881 bof
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// fix 2881 eof

Vue.use(Router);

import appRoutes from './router-view-config';

export function createRouter () {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: appRoutes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        VueScrollTo.scrollTo(to.hash, 700);
        return { selector: to.hash };
      }
      return { x: 0, y: 0 };
    },
  });
}
