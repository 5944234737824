<template>
  <wizard-wrapper>
    <template v-slot:message>
      Great, we're almost finished. Next we need to collect your banking information for the
      <strong>{{ formData.bankAccountType }} account</strong> where you will receive funds if your
      request is successfully approved.
    </template>
    <form @submit.prevent="validateBeforeSubmit">

      <fieldset class="form__fieldset">
        <Combobox
          label="What's your bank's name?"
          id="bankName"
          name="bankName"
          v-model="bankName"
          v-validate="'required'"
          :exval="validationAttempt"
          errormsg="This is a required field"
          :error="errors.first('bankName')"
          :items="banksList"
          :totalCount="banksTotalCount"
        />
      </fieldset>
      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WizardWrapper from '../components/WizardWrapper';
import FormButton from '../../base/components/FormButton';
import formsRouterHooks from '../../base/mixins/formsRouterHooks';

export default {
  name: 'BankName',
  title: 'Apply | Step 20',
  mixins: [formsRouterHooks],
  components: {
    WizardWrapper,
    FormButton,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      bankName: '',
      banksList: [],
      banksTotalCount: null,
    };
  },
  methods: {
    ...mapActions(['saveFormData', 'searchBank', 'searchAba']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            bankName: this.bankName,
          });

          this.searchAba()
            .then((needSelectAba) => {
              if (needSelectAba) {
                this.completeStep();
              } else {
                this.completeStep('apply22');
              }
            })
            .finally(() => { this.saving = false; });
        }
      });
    },
  },
  watch: {
    bankName() {
      if (this.bankName.length >= 2) {
        this.searchBank(this.bankName).then((data) => {
          this.banksList = data.banks || [];
          this.banksTotalCount = data.total_count;
        });
      } else {
        this.banksList = [];
      }
    },
  },
  computed: {
    ...mapGetters(['formData']),
  },
  created() {
    this.bankName = this.formData.bankName || '';
  },
};
</script>
