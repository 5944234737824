<template>
  <!-- eslint-disable max-len -->
  <wizard-wrapper :percentageProp="90">
    <template v-slot:message>
      Thanks for all that, {{auth.userDetails.firstName}}. Just one last question before we can review your details, please confirm your next pay day.
    </template>

    <form @submit.prevent="validateBeforeSubmit">
      <fieldset class="form__fieldset">
        <Select
          label="When is your next pay day?"
          id="nextPaydate"
          name="nextPaydate"
          v-model="nextPaydate"
          v-validate="'required'"
          :options="paymentDates"
          errormsg="This is a required field"
          :error="errors.first('nextPaydate')"
        />
      </fieldset>
      <form-button :hasErrors="errors.items.length != 0 && validationAttempt"
                   :buttonLabel="saving ? 'Saving...' : 'Continue'"></form-button>
    </form>
  </wizard-wrapper>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import WizardWrapper from '../../components/WizardWrapper';
import FormButton from '../../../base/components/FormButton';
import formsRouterHooks from '../../../base/mixins/formsRouterHooks';

export default {
  name: 'Returning6',
  title: 'Welcome Back',
  mixins: [formsRouterHooks],
  components: {
    FormButton,
    WizardWrapper,
  },
  data() {
    return {
      validationAttempt: false,
      saving: false,
      payFrequency: '',
      nextPaydate: '',
      secondPayDate: '',
    };
  },
  methods: {
    ...mapActions(['saveFormData']),
    validateBeforeSubmit() {
      this.saving = true;
      this.validationAttempt = true;
      this.$validator.validate().then((result) => {
        if (result) {
          this.saveFormData({
            nextPaydate: this.nextPaydate,
            secondPayDate: this.secondPayDate,
          });
          this.$router.push({ path: '/users/7' });
          return;
        }
        this.saving = false;
      });
    },
    isWeekend(date) {
      if (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
        return true;
      }
      return false;
    },
    // eslint-disable-next-line consistent-return
    calculateSecondPaymentDate() {
      if (this.nextPaydate === '' || this.payFrequency === '') {
        return false;
      }
      const secondPayDate = moment(this.nextPaydate, 'YYYY-MM-DD');

      switch (this.payFrequency) {
      case 'weekly':
        secondPayDate.add(1, 'weeks');
        break;
      case 'biweekly':
        secondPayDate.add(2, 'weeks');
        break;
      case 'semi-monthly':
        secondPayDate.add(15, 'days');
        break;
      case 'monthly':
        secondPayDate.add(1, 'months');
        break;
      default:
        secondPayDate.add(1, 'months');
      }

      while (this.isWeekend(secondPayDate)) {
        secondPayDate.subtract(1, 'days');
      }
      this.secondPayDate = secondPayDate.format('YYYY-MM-DD');
    },
  },
  watch: {
    nextPaydate: 'calculateSecondPaymentDate',
  },
  computed: {
    ...mapGetters(['auth', 'formData']),
    paymentDates() {
      let now = moment();
      const paymentDates = [];
      let thisDay = now.isoWeekday();
      const daysThisWeek = 5 - thisDay;
      const daysNextWeek = (7 - thisDay) + 5;
      let prefix = '';
      let count = 0;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 30; i++) {
        // Make it next day
        now = now.add(1, 'days');
        thisDay = thisDay === 7 ? 1 : thisDay + 1;
        switch (true) {
        case i === 0:
          prefix = 'Tomorrow, ';
          break;
        case count <= daysThisWeek:
          prefix = 'This ';
          break;
        case count > daysThisWeek && count <= daysNextWeek:
          prefix = 'Next ';
          break;
        default:
          prefix = '';
        }
        if (thisDay !== 6 && thisDay !== 7) {
          paymentDates.push({ value: now.format('YYYY-MM-DD'), label: prefix + now.format('dddd, MM-DD-YYYY') });
        }
        // eslint-disable-next-line no-plusplus
        count++;
      }
      return paymentDates;
    },
  },
  created() {
    this.payFrequency = this.auth.userDetails.payFrequency || 'biweekly';
    this.nextPaydate = this.formData.nextPaydate || '';
    this.secondPayDate = this.formData.secondPayDate || '';
  },
};
</script>
