import CreateApp from '@/code/Core/Bundle/Vue/view/createApp';
import Api from './services/Api'
import clientEvents from './clientEvents'
import gtmConfig from '@/code/Core/Bundle/GoogleTagManager/Resources/default_config';
import { createRouter } from './router'
import store from './store'

export default (params, context={}) => {
  const app = CreateApp({
    gtmConfig: gtmConfig,
    eventsList: clientEvents,
    router: createRouter(),
    store,
    ...params
  }, context);

  Api.defaults.headers.common['csrf-token'] = app.store.getters.csrfToken;

  return app;
};
