import Api from '../../../services/Api';

export default {
  saveFormData({commit}, data) {
    commit('saveFormData', data);
  },
  saveSplitTestData({commit}, data) {
    commit('saveSplitTestData', data);
  },
  saveVisitData({commit, getters, state}, data) {
    commit('mergeVisitData', data);
    const visitData = getters.visitData;

    let sessionID = '';
    let sessionMatched = false;
    // Check if session data exists locally and is still valid
    if (localStorage.getItem('session')) {
      const session = JSON.parse(localStorage.getItem('session'));
      if (session.sessionID !== '') {
        const now = new Date();
        if (now < session.expires) {
          // Session is valid, check for matches
          if (
            session.webmasterID === visitData.webmasterID
            && session.subaccount === visitData.subaccount
            && session.subid1 === visitData.subid1
            && session.subid2 === visitData.subid2
            && session.subid3 === visitData.subid3
            && session.subid4 === visitData.subid4
          ) {
            sessionMatched = true;
            sessionID = session.sessionID;
          } else {
            localStorage.removeItem('session');
          }
        } else {
          // Session has expired, remove it
          localStorage.removeItem('session');
        }
      }
    }

    const params = {
      ...visitData,
      sessionID,
      type: state.formType,
    };

    if (sessionMatched && sessionID) {
      Api.patch('click/', {sessionID})
        .then(() => {
          commit('saveSessionID', {...params});
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Api.post('click/', params).then((response) => {
        commit('saveSessionID', {...params, sessionID: response.data.sessionID});
      }).catch((err) => {
        console.log(err);
      });
    }
  },
  registerPoint({state}, data) {
    const params = {
      sessionID: state.visitData.sessionID,
      point: data,
    };

    Api.post('point/', params).catch((err) => {
      console.log(err);
    });
  },
  async getIP(context) {
    const endpoint = 'ip';
    try {
      const response = await Api.get(endpoint);
      return response.data.success ? response.data.ip : 'none';
    } catch (err) {
      return 'none';
    }
  },
  async getCityState(context, zipcode) {
    if (zipcode.length !== 5) {
      return;
    }
    const endpoint = 'zipcode';
    try {
      const response = await Api.get(`${endpoint}/${zipcode}`);
      if (response.data.found) {
        return response.data.data;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async unsubscribePhone(context, phone) {
    const endpoint = `unsubscribe/phone`;
    try {
      const response = await Api.get(`${endpoint}?value=${phone}`);
      return response.data.success;
    } catch (err) {
      console.log(err)
      return false;
    }
  },
  async unsubscribeEmail(context, email) {
    const endpoint = `unsubscribe/email`;
    try {
      const response = await Api.get(`${endpoint}?value=${email.toLowerCase()}`);
      return response.data.success;
    } catch (err) {
      console.log(err)
      return false;
    }
  },
  async checkEmail({commit}, data) {
    const endpoint = 'recognize-email';
    try {
      const response = await Api.post(endpoint, {email: data.email.toLowerCase()});
      if (response.data.success) {
        commit('setRecogniseEmail', {recognised: true, name: data.name, email: data.email});
        return true;
      } else {
        commit('setRecogniseEmail', {recognised: false, name: '', email: ''});
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async checkPhoneRedirect({commit}, data) {
    const endpoint = 'recognize-email';
    try {
      const response = await Api.post(endpoint, {phone: data});
      return !response.data.success;
    } catch(err) {
      console.log(err);
    }
  },
  async checkEmailRedirect({commit}, data) {
    const endpoint = 'recognize-email';
    try {
      const response = await Api.post(endpoint, {email: data});
      return !response.data.success;
    } catch(err) {
      console.log(err);
    }
  },
  async verifyEmail({commit}, data) {
    const endpoint = 'verify-email';
    try {
      const response = await Api.post(endpoint, { email: data.email, ssn_last4: data.ssn_last4 });
      if (response.data.body) {
        commit('setAuthoriseUser', {
          authorised: true,
          userDetails: {
            clientKey: response.data.clientKey,
            ...response.data.body,
          },
        });
        return true;
      } else {
        commit('setAuthoriseUser', {
          authorised: false,
          userDetails: {},
        });
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async verifyPhone({commit}, data) {
    const endpoint = 'verify-phone';
    try {
      const response = await Api.post(endpoint, { phone: data.phone, ssn_last4: data.ssn_last4 });
      if (response.data.body) {
        commit('setAuthoriseUser', {
          authorised: true,
          userDetails: {
            clientKey: response.data.clientKey,
            ...response.data.body,
          },
        });
        return true;
      } else {
        commit('setAuthoriseUser', {
          authorised: false,
          userDetails: {},
        });
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  },
  checkUserAgentHistory({commit}) {
    const endpoint = 'lookup-agent';
    Api.get(endpoint).then((response) => {
      if (response.data.success === true) {
        commit('setUseragentRecogniseUser', {recognised: true, name: response.data.name});
      } else {
        commit('setUseragentRecogniseUser', {recognised: false, name: ''});
      }
    }).catch((err) => {
      console.log(err);
    });
  },
  async verifyAgent({state, commit}, data) {
    const endpoint = 'verify-agent';
    try {
      const response = await Api.post(endpoint, {zipcode: data.zipcode, ssn_last4: data.ssnLast4, email: state.auth.userDetails.email}, {
        headers: {
          'X-Parameter': 'GET',
        },
      });
      if (response.data.body) {
        commit('setAuthoriseUser', {
          authorised: true,
          userDetails: {
            clientKey: response.data.clientKey,
            ...response.data.body,
          },
        });
        return true;
      } else {
        commit('setAuthoriseUser', {
          authorised: false,
          userDetails: {},
        });
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async sendToAPI({commit, state, rootGetters}) {
    const loanAmount = rootGetters.loanAmounts.find((obj) => obj.value === state.formData.loanAmount);

    const params = {
      sessionID: state.visitData.sessionID,
      sourceCampaign: state.visitData.sc,
      sourceKey: state.visitData.sk,
      webmasterID: state.visitData.webmasterID,
      subaccount: state.visitData.subaccount,
      siteURL: window.location.host,
      subid1: state.visitData.subid1,
      subid2: state.visitData.subid2,
      subid3: state.visitData.subid3,
      subid4: state.visitData.subid4,
      loanAmount: state.formData.loanAmount,
      loanAmountRange: loanAmount.range,
      loanPurpose: state.formData.loanPurpose,
      creditScore: state.formData.creditScore,
      firstName: state.formData.firstName,
      lastName: state.formData.lastName,
      email: state.formData.email,
      dob: reformatDate(state.formData.dob),
      zip: state.formData.zipcode,
      city: state.formData.city,
      state: state.formData.address_state,
      address: state.formData.address,
      monthsAtAddress: state.formData.monthsAtAddress,
      homeOwnership: state.formData.homeOwnership,
      driversLicense: state.formData.driversLicense,
      driversLicenseState: state.formData.driversLicenseState,
      cellPhone: state.formData.phone,
      homePhone: state.formData.phone,
      ssn: state.formData.ssn,
      additionalField: state.formData.additionalField,
      employmentType: state.formData.employmentType,
      employerName: state.formData.employerName,
      monthsEmployed: state.formData.monthsEmployed,
      monthlyIncome: state.formData.monthlyIncome,
      payFrequency: state.formData.payFrequency,
      nextPayDate: state.formData.nextPaydate,
      secondPayDate: state.formData.secondPayDate,
      workPhone: state.formData.workPhone,
      workExt: state.formData.workExt,
      payType: state.formData.payType,
      bankRoutingNumber: state.formData.bankRoutingNumber,
      bankName: state.formData.bankName,
      bankAccountNumber: state.formData.bankAccountNumber,
      bankAccountType: state.formData.bankAccountType,
      monthsAtBank: state.formData.monthsAtBank,
      totalDebtAmount: state.formData.debtAmount,
      tags: {
        ...state.splitTest,
      },
      additionalVerticals: {
        ...state.formData.additionalVerticals,
      },
      leadType: state.formType,
      jornayaLeadID: state.formData.jornayaLeadID,
    };

    if (state.formData.clientKey) {
      params.clientKey = state.formData.clientKey;
      params.form = 'short';
    } else {
      params.form = 'long';
    }

    return Api.post('leads/', params).then((response) => {
      commit('saveFormData', {apiResponse: response.data.status, apiRedirectURL: response.data.redirectURL});
      // save login
      localStorage.setItem('user', JSON.stringify({email: state.formData.email, name: state.formData.firstName}));

      return {
        status: response.data.status || false,
        isDeclined: response.data.isDeclined || false,
        redirectURL: response.data.redirectURL
      }
    }).catch((err) => {
      console.log('Error Sending:', err);
    });
  },
  async searchAba({commit, state, dispatch}){
    const params = {
      bankName: state.formData.bankName,
      state: state.formData.address_state,
    };

    return Api.post('search-aba/', params)
    .then((result) => {
      const data = result.data;
      commit('setAbasList',data);

      if(data.length===0){
        dispatch('markStepComplete', 'apply21');
      }

      if(data.length===1 || data.length >= 2 && data[0].count/data[1].count > 20) {
        commit('saveFormData', {
          bankRoutingNumber: String(data[0].routing),
        });
        dispatch('markStepComplete', 'apply21');
      }

      return data.length >= 2 && data[0].count/data[1].count < 20;
    })
    .catch((err) => {
      console.log(err);
    });
  },
  async searchBank(context, pattern){
    return Api.post('search-bank/', { pattern })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      console.log(err);
    });
  }
};

function reformatDate(dateString = '01/01/1970') {
  const formatted = dateString.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
  if (formatted) {
    return `${formatted[3]}-${formatted[1]}-${formatted[2]}`;
  }
  return '1970-01-01';
}
