export default [
  {
    name: 'apply1',
    path: '1',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/LoanAmount'),
    alias: ['/registration'],
  },
  {
    name: 'apply2',
    path: '2',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/LoanPurpose.vue'),
  },
  {
    name: 'apply2b',
    path: '2b',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/DebtAmount.vue'),
  },
  {
    name: 'apply3',
    path: '3',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/CreditScore.vue'),
  },
  {
    name: 'apply4',
    path: '4',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/BankAccountType.vue'),
  },
  {
    name: 'apply4reject',
    path: '4/declined',
    meta: {
      beforeRouteEnter: (store) => (!store.getters.isFilledStep('apply4') ? '/registration/4' : null),
    },
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/WithoutBankAccount.vue'),
  },
  {
    name: 'apply5',
    path: '5',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/Zipcode.vue'),
  },
  {
    name: 'apply6',
    path: '6',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/Email.vue'),
  },
  {
    name: 'apply7',
    path: '7',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/CustomerName.vue'),
  },
  {
    name: 'apply8',
    path: '8',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/Address.vue'),
  },
  {
    name: 'apply9',
    path: '9',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/MonthsAtAddress.vue'),
  },
  {
    name: 'apply10',
    path: '10',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/HomeOwnership.vue'),
  },
  {
    name: 'apply11',
    path: '11',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/PhoneNumber.vue'),
  },
  {
    name: 'apply12',
    path: '12',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/DateOfBirth.vue'),
  },
  {
    name: 'apply12b',
    path: '12b',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/Medicare.vue'),
  },
  {
    name: 'apply12c',
    path: '12c',
    component: () => import(/* webpackChunkName: "apply" */ '../../view/frontend/chatBot/wizard/DebtRelief.vue'),
  },
  {
    name: 'apply13',
    path: '13',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/MonthlyIncome.vue'),
  },
  {
    name: 'apply14',
    path: '14',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/EmploymentType.vue'),
  },
  {
    name: 'apply15',
    path: '15',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/PayFrequency.vue'),
  },
  {
    name: 'apply16',
    path: '16',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/PayType.vue'),
  },
  {
    name: 'apply17',
    path: '17',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/EmployerName.vue'),
  },
  {
    name: 'apply18',
    path: '18',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/MonthsEmployed.vue'),
  },
  {
    name: 'apply19',
    path: '19',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/WorkPhone.vue'),
  },
  {
    name: 'apply20',
    path: '20',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/BankName.vue'),
  },
  {
    name: 'apply21',
    path: '21',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/SelectAba.vue'),
  },
  {
    name: 'apply22',
    path: '22',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/BankAccountNumber.vue'),
  },
  {
    name: 'apply23',
    path: '23',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/MonthsAtBank.vue'),
  },
  {
    name: 'apply24',
    path: '24',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/SSN.vue'),
  },
  {
    name: 'apply25',
    path: '25',
    component: () => import(/* webpackChunkName: "apply2" */ '../../view/frontend/chatBot/wizard/DriversLicense.vue'),
  },
  {
    name: 'processingLong',
    path: 'processing',
    // eslint-disable-next-line max-len,vue/max-len
    component: () => import(/* webpackChunkName: "processing" */ '../../view/frontend/base/components/form/Processing.vue'),
  },
];
