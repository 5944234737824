<template>
  <main>
    <header class="main-header">
      <slot name="header">
        Header
      </slot>
    </header>
  </main>
</template>

<script>
  export default {
    name: 'home',
    title: 'Blank Title',
  };
</script>
